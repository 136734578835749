import React from 'react'
import { Link } from "gatsby";
import Footer from './Footer'
import avatar from '../assets/images/red-idea_white_no_slogan.png'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                
                <div><center>
                    <img src={avatar} width='200px' alt="" />
                    <h3><i>Creative Solutions in a Complex World</i></h3>
                    <p></p>
                    <Link to={`/blog`} className="button">All Articles</Link>
                    <p></p>
                    <Link to={`/tutorials`} className="button">Tutorials</Link>
                    <p></p>
                    <Link to={`/reviews`} className="button">Reviews</Link>
                    <p></p>
                    <Link to={`/blog/posts/tags/`} className="button">By Tags</Link>
                            </center>
                     
                </div>
                
                <Footer />
            </header>
        )
    }
}

export default Header
